import React, { useState, useEffect } from "react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Link, useNavigate } from 'react-router-dom'
import './styles.css';
import api from '../../services/api';
import imgTopo from '../../assets/logo dev.jpg'
import imgPerfil from '../../assets/perfil.jpg'
import imgInstagram from '../../assets/instagram.png'
import imgLinkedin from '../../assets/linkedin.png'
import imgEmail from '../../assets/email.png'
import imgWhatsApp from '../../assets/whatsApp.png'

export default function Home() {
  const login = localStorage.getItem('login');
  const accessToken = localStorage.getItem('accessToken');
  const id = localStorage.getItem('id');
  const nome = localStorage.getItem('nome');
  const navigate = useNavigate();
  const [dados, setDados] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [quantidadeIds, setQuantidadeIds] = useState(0);

  useEffect(() => {
    api.get('projetos/getList')
      .then(response => {
        const projetos = response.data.body;
        setDados(response.data.body);
        setQuantidadeIds(projetos.length);
      })
      .catch(error => {
        console.error('Erro na requisição:', error);
      });
  }, []);

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const handleVoltar = () => {
    window.location.href = 'http://localhost:3000/';
  };

  const instagramFunc = () => {
    window.open('https://www.instagram.com/erique.dev', '_blank');
  };
  const linkedinFunc = () => {
    window.open('https://www.linkedin.com/in/erique-rocha-dev', '_blank');
  };
  const emailFunc = () => {
    window.open('mailto:eriquebit@gmail.com', '_blank');
  };
  const whatsAppFunc = () => {
    window.open('https://wa.me/+5591984289712', '_blank');
  };

  return (
    <div>

      <div className="navbar">
        <img src={imgTopo} alt="logo erique dev" />
      </div>
      <div className="posicao-perfil">
        <img src={imgPerfil} className="perfil" alt="perfil erique" />
      </div>

      <div className="barra-de-redes">
        <div className="redes">
          <img onClick={instagramFunc} src={imgInstagram} className="icone-social" alt="icone instagram" />

        </div>
        <div className="redes">
          <img onClick={linkedinFunc} src={imgLinkedin} className="icone-social" alt="icone linkedin" />

        </div>
        <div className="redes">
          <img onClick={emailFunc} src={imgEmail} className="icone-social" alt="icone email" />

        </div>
        <div className="redes">
          <img onClick={whatsAppFunc} src={imgWhatsApp} className="icone-social" alt="icone whatsApp" />
        </div>
      </div>
      <div>
        <h2 className="meu-nome">Erique Rocha</h2>
        <h3 className="minha-funcao">Desenvolvedor backend</h3>
      </div>

      <div className="sobre-container">
        <h1>Sobre</h1>
        <p>&nbsp;&nbsp;&nbsp;&nbsp; Olá, Sou desenvolvedor backend. Há dois anos venho criando projetos próprios utilizando o framework Spring Boot.
Além disso, nestes dois anos, também expandi minhas habilidades para o desenvolvimento Android, utilizando a linguagem Kotlin.
Minha jornada no desenvolvimento não se limita ao backend e mobile, pois por necessidade, estudei e pratiquei frontend, incorporando tecnologias como React e Vue.</p>
      </div>
        <h2 className="sublinhado">Habilidades</h2>
      <div className="container-skill">
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg" />
          <p>Spring Boot</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg" />
          <p>Java</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg" />
          <p>Android</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/androidstudio/androidstudio-original.svg" />
          <p>Android Studio</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kotlin/kotlin-original.svg" />
          <p>Kotlin</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" />
          <p>PostgreSQL</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
          <p>React</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/intellij/intellij-original.svg" />
          <p>Intellij</p>
        </div>
        <div className="skill">
          <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg" />
          <p>VScode</p>
        </div>
      </div>
        <h2 className="sublinhado">Projetos</h2>

      <div className="container-projetos">
        {Array.isArray(dados) && dados.map((projeto, index) => (
          <div key={index} className={`projeto ${index % 2 === 0 ? 'esquerda' : 'direita'}`} onClick={() => handleExpand(index)}>
            <h2>{projeto.nome}</h2>
            <div className="img-lado-a-lado">
              {projeto.imagePaths && projeto.imagePaths.map((imagePath, imgIndex) => (
                <img key={imgIndex} src={imagePath} alt={`Imagem ${imgIndex + 1}`} />
              ))}
            </div>
            {expandedIndex === index && (
              <>
                <p>{projeto.descricaoBakc}</p>
                <p>Repositório Backend: <a href={projeto.repositorioBack} target="_blank" rel="noopener noreferrer">{projeto.repositorioBack}</a></p>
                <p>Link Backend: <a href={projeto.linkBakc} target="_blank" rel="noopener noreferrer">{projeto.linkBakc}</a></p>
                <p>{projeto.descricaoFront}</p>
                <p>Repositório Frontend: <a href={projeto.repositorioFront} target="_blank" rel="noopener noreferrer">{projeto.repositorioFront}</a></p>
                <p>Link Frontend: <a href={projeto.linkFront} target="_blank" rel="noopener noreferrer">{projeto.linkFront}</a></p>
              </>
            )}
          </div>
        ))}
      </div>
      <footer className="rodape">
        <div className="direitos-autorais">
          <p>&copy; 2024 Erique Rocha. Todos os direitos reservados.</p>
        </div>
      </footer>
      <div className="ass">
        <a href="https://www.erique.dev" target="_blank">erique.dev</a>
      </div>

    </div>
  );
}