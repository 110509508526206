import React, { useState } from 'react';
import api from '../../services/api';

const ProjetoForm = () => {
    const token = localStorage.getItem('token');

  const [formData, setFormData] = useState({
    nome: '',
    descricaoBack: '',
    repositorioBack: '',
    linkBack: '',
    descricaoFront: '',
    repositorioFront: '',
    linkFront: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar se o token está disponível
    if (!token) {
      console.error('Token não encontrado');
      return;
    }

    try {
      const response = await api.post('/projetos/save', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      // Aqui você pode lidar com a resposta da requisição, se necessário
      console.log('Requisição enviada com sucesso', response.data);
    } catch (error) {
      console.error('Erro:', formData);
      console.error('Erro:', error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Nome:
        <input
          type="text"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
        />
      </label>
      <label>
        Descrição Backend:
        <input
          type="text"
          name="descricaoBack"
          value={formData.descricaoBack}
          onChange={handleChange}
        />
      </label>
      <label>
        Repositório Backend:
        <input
          type="text"
          name="repositorioBack"
          value={formData.repositorioBack}
          onChange={handleChange}
        />
      </label>
      <label>
        Link Backend:
        <input
          type="text"
          name="linkBack"
          value={formData.linkBack}
          onChange={handleChange}
        />
      </label>
      <label>
        Descrição Frontend:
        <input
          type="text"
          name="descricaoFront"
          value={formData.descricaoFront}
          onChange={handleChange}
        />
      </label>
      <label>
        Repositório Frontend:
        <input
          type="text"
          name="repositorioFront"
          value={formData.repositorioFront}
          onChange={handleChange}
        />
      </label>
      <label>
        Link Frontend:
        <input
          type="text"
          name="linkFront"
          value={formData.linkFront}
          onChange={handleChange}
        />
      </label>
      <button type="submit">Enviar</button>
    </form>
  );
};

export default ProjetoForm;
